.delete {
  position: relative;
  top: 16px;
  right: 15px
}

footer svg,
.button svg,
.delete svg {
  filter: drop-shadow(3px 3px 3px #999);
  transition: 0.3s;
  transform: rotate(-0.2deg)
}

footer svg:hover,
.button svg:hover,
.delete svg:hover {
  filter: drop-shadow(4px 4px 4px #999);
  transform: rotate(-0.6deg);
  cursor: pointer
}

.delete svg {
  filter: drop-shadow(1px 1px 1px #999)
}

/* Loading */
.loading-wrapper,
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loading {
  width: 200px;
  height: 200px;
}

.loading .background {
  border-radius: 50%;
  background: #6762a6;
  border: 3px solid #c9c3e6;
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.25);
  width: 200px;
  height: 200px;
  box-sizing: border-box;
  animation: pulse-colors 2s infinite alternate linear;
}

.loading i {
  margin: 25px 5px 5px 55px;
  float: left;
  font-size: 10em !important;
  color: #fff;
  animation: pulse-icon 4s infinite alternate linear;
}

#notification-wrapper {
  position: absolute;
}

.validation-message span {
  color: red;
}

.cat-fav .card {
  position: relative;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.galleryOuter {
  padding: 20px 0 30px 0;
  /* overflow: auto;
  max-height: 380px; */
}

.GallInnBx {
  width: 150px;
  margin-right: 20px;
  padding: 3px;
  box-shadow: #ccc 0 0 5px 2px;
  border-radius: 3px;
  position: relative;
  height: 100px;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 25px;
}

.GallInnBx .delete {
  position: absolute;
  right: -10px;
  top: -10px;
}

.GallInnBx img {
  width: 100%;
  height: 100%;
}

.GalleryHd {
  font-size: 18px;
  color: #000;
  font-weight: 600;
  padding-bottom: 10px;
}

.GalleryHd span {
  font-size: 16px;
  font-weight: 400;
  color: #666;
  padding-left: 4px;
}

@keyframes pulse-colors {
  from {
    background: #c9c3e6;
    border: 3px solid #a29ccc;
  }

  to {
    background: #6762a6;
    border: 3px solid #c9c3e6;
  }
}

/* Animations */
.fadein {
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.spinner {
  animation: spin 1.5s infinite linear;
  width: 50px;
  height: 50px;
  margin-bottom: 15px;
}

.spinner svg.svg-inline--fa.fa-fw {
  width: 50px;
  background: none;
  height: 50px;
  border-radius: 50%
}


@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg)
  }

  100% {
    -webkit-transform: rotate(360deg)
  }
}

.rolling {
  position: absolute;
  animation: move 6s ease-out;
  filter: drop-shadow(4px 4px 4px #999);
}

@-webkit-keyframes move {
  0% {
    left: -5%;
  }

  100% {
    left: 105%;
  }
}

@keyframes pulse-icon {
  from {
    text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.25);
  }

  to {
    text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.55);
  }
}

.btnOuter {
  margin-bottom: 20px;
}

label.chooseBtn {
  background: #fff;
  box-shadow: #ccc 0 0 5px 2px;
  display: block;
  /* margin-bottom: 10px; */
  width: 190px;
  font-size: 16px;
  /* line-height: 37px; */
  padding: 10px;
  border-radius: 3px;
  cursor: pointer;
}

label.chooseBtn svg {
  width: 25px;
  display: inline-block;
  vertical-align: top;
  line-height: 25px;
  height: 25px;
  margin-left: 10px;
}

button.expendImg {
  width: 100%;
  text-align: left;
}

.expandIcon {
  float: right;
  font-size: 25px;
}

.fw-bold {
  font-size: 18px;
  color: #000;
  font-weight: 600;
}

.react-autosuggest__input {
  width: 100%;
  border: 1px solid rgb(180, 180, 215);
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 8px;
}

.react-autosuggest__suggestions-list {
  background-color: white;
  border: 1px solid rgb(180, 180, 215);
  border-radius: 4px;
  list-style-type: none;
  padding: 0%;
  padding-top: 4px;
  max-height: 25vh;
  overflow: auto;
}

.react-autosuggest__suggestion {
  padding: 8px;
  border-bottom: 1px solid rgb(180, 180, 215);
  padding-left: 12px;
}

.react-autosuggest__suggestion:hover {
  background: rgb(235, 235, 235);
  cursor: pointer;
}

.MuiSelect-selectMenu {
  height: auto !important;
  min-height: unset !important;
}

label.MuiFormLabel-root {
  padding: 3px;
}

.bg-redd{
  border: 4px solid rgba(239, 163, 163, 0.514);
  border-radius: 8px;
  }
  .pp-0{
    padding: 0px;
  }

  .market-bg-redd{
    background-color:  rgba(239, 163, 163, 0.514);
  }

  .mr-12{
    margin-right: 12px;
  }

    