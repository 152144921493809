

.form-material hr{border: 0!important;}
.form-material input[type="text"]{    top: 0px!important;
    height: 33px!important;
    padding: 0 10px!important;
    font-size: 14px;
    webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    border: 1px solid #ccc!important;
    border-radius: 4px;
    width: 100%!important}

.form-material div:first-child div{width:100%!important}