.rw-state-readonly .rw-widget-input, .rw-filter-input {
    background: #eef2f5 !important;
}

.warning-title{
    font-size: large;
    font-weight: 600;
}

.increase-size{
    font-size: larger;
    font-weight: 700;
}



