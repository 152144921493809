.react-select-error > .rw-widget-input {
    border-color: red !important;
}

.date-select-error > .rw-widget-picker{
    border-color: red !important; 
}

.multi-select-error > .Select-control {
    border-color: red !important; 
}

.image-upload-error > .form-control {
    border-color: red !important; 
}

.street-address-error {
    border-color: red !important; 
}

#imagePage {
    width: 100%; padding: 6px; background-color: #fff; border: 1px solid #c2cad8; border-radius: 4px; box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%); transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s; font-size: 14px; color: #555; display: block; outline: 0!important; box-shadow: none!important;
}