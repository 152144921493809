.MuiInputBase-root{
    width: 100%;
    margin: 0px 0 20px 0;
}


.MuiSelect-root {
    width: 100%;
    border: 1px solid #ccc!important;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    border-radius: 4px!important;
    padding: 10px 16px!important;
    font-size: 15px!important;
    color: #9d9d9d!important;
}

.MuiInput-underline:before{
    border-bottom: 0!important
}


.MuiMenuItem-root{
    font-size:15px!important; 
}

label.MuiFormLabel-root{
    font-size: 14px;
    color: #333;
}